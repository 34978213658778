<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Item</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-600px scroll-y mx-5 py-7">
        <v-row>
          <v-col cols="12">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="consignee.name"
                label="Name"
                :error-messages="nameErrors"
                @blur="$v.consignee.name.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="consignee.email"
                label="Email"
                :error-messages="emailErrors"
                @blur="$v.consignee.email.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="consignee.phone"
                label="Phone"
                :error-messages="phoneErrors"
                @blur="$v.consignee.phone.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-autocomplete
                v-model="address.country_id"
                :items="$store.getters.getSUBSCRIPTIONBUYERSCountries"
                item-text="text"
                item-value="index"
                label="Country"
                dense
                outlined
                clearable
                :error-messages="countryIdErrors"
                @blur="$v.address.country_id.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.state"
                label="State"
                :error-messages="stateErrors"
                @blur="$v.address.state.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.city"
                label="City"
                :error-messages="cityErrors"
                @blur="$v.address.city.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.town"
                label="Town"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.province"
                label="Province"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.area"
                label="Area"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.address"
                label="Address"
                :error-messages="addressErrors"
                @blur="$v.address.address.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.zipCode"
                label="Postal code"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.route"
                label="Route"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="h-55px">
              <v-autocomplete
                v-model="address.currency_id"
                :items="$store.getters.getSUBSCRIPTIONBUYERSCurrencies"
                item-text="text"
                item-value="index"
                label="Currency"
                dense
                outlined
                clearable
                :error-messages="currencyIdErrors"
                @blur="$v.address.country_id.$touch()"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="address.comment"
                label="Comment"
                hide-details
              />
            </div>
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import {
  SET_COUNTRIES,
  SET_CURRENCIES,
} from "@/core/services/store/subscriptionBuyers.module";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  validations: {
    consignee: {
      name: { required },
      phone: { required },
      email: { required },
    },
    address: {
      country_id: { required },
      state: { required },
      city: { required },
      address: { required },
      currency_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    id: null,
    consignee: {
      name: null,
      phone: null,
      email: null,
    },
    address: {
      country_id: null,
      state: null,
      city: null,
      town: null,
      province: null,
      area: null,
      address: null,
      zipCode: null,
      route: null,
      currency_id: null,
      comment: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      let sendData = { id: this.$store.getters.getItemForAction.id };
      this.pageLoader(true);
      ApiService.post("/order_management/subscription_buyers/edit", sendData)
        .then(({ data }) => {
          this.$store.commit(SET_CURRENCIES, data.currencies);
          this.$store.commit(SET_COUNTRIES, data.countries);
          this.id = data.subscription_buyer.id;
          this.consignee = data.subscription_buyer.consignee;
          this.address = data.subscription_buyer.address;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    handleConsigneeValidation(fieldName) {
      const errors = [];
      if (!this.$v.consignee[fieldName].$dirty) return errors;
      if ("required" in this.$v.consignee[fieldName]) {
        !this.$v.consignee[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    handleAddressValidation(fieldName) {
      const errors = [];
      if (!this.$v.address[fieldName].$dirty) return errors;
      if ("required" in this.$v.address[fieldName]) {
        !this.$v.address[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      let sendData = {
        id: this.id,
        consignee: this.consignee,
        address: this.address,
      };

      this.pageLoader(true);
      ApiService.post(`/order_management/subscription_buyers/update`, sendData)
        .then(() => {
          swalEdited();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.id = null;
      this.consignee = {
        name: null,
        phone: null,
        email: null,
      };
      this.address = {
        country_id: null,
        state: null,
        city: null,
        town: null,
        province: null,
        area: null,
        address: null,
        zipCode: null,
        route: null,
        currency_id: null,
        comment: null,
      };
    },
  },
  computed: {
    nameErrors: function () {
      return this.handleConsigneeValidation("name");
    },
    phoneErrors: function () {
      return this.handleConsigneeValidation("phone");
    },
    emailErrors: function () {
      return this.handleConsigneeValidation("email");
    },
    countryIdErrors: function () {
      return this.handleAddressValidation("country_id");
    },
    stateErrors: function () {
      return this.handleAddressValidation("state");
    },
    cityErrors: function () {
      return this.handleAddressValidation("city");
    },
    addressErrors: function () {
      return this.handleAddressValidation("address");
    },
    currencyIdErrors: function () {
      return this.handleAddressValidation("currency_id");
    },
  },
};
</script>
